import React, { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { Button, Container, Form, Spinner, Col } from "react-bootstrap"
import Recaptcha from "react-grecaptcha"

import Layout from "../layout"
import SEO from "../components/seo"

const ContactUsPage = () => {
  const recaptchaRef = useRef(null)
  const [submitted, setSubmitted] = useState(false)
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    errors,
    formState: { isSubmitting },
  } = useForm()
  const required = "This field is required"

  useEffect(() => {
    register({ name: "recaptcha", type: "custom" }, { required: required })
  }, [register])

  const onSubmit = async (data) => {
    try {
      // fetch api
      const response = await fetch(process.env.CONTACT_US_URL, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        body: JSON.stringify(data),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })

      // parse json body
      const body = await response.json()

      // error handling
      let error
      if (response.status >= 400) {
        error = new Error(body.error || "Form error")
        error.name = body.name || "submit"
        throw error
      }

      // success!
      setSubmitted(true)
    } catch (error) {
      setError(error.name, { type: "manual", message: error.message })
    }
  }

  const showThankYou = (
    <div className="msg-confirm">
      <p>Your message was sent!</p>
    </div>
  )

  const showForm = (
    <Form onSubmit={handleSubmit(onSubmit)} method="post">
      <Form.Row>
        <Form.Group controlId="form-first-name" as={Col}>
          <Form.Label>First Name *</Form.Label>
          <Form.Control
            type="text"
            placeholder=""
            name="first_name"
            autoComplete="given-name"
            ref={register({ required })}
            disabled={isSubmitting}
            isInvalid={!!errors.first_name}
          />
          {errors.first_name && (
            <Form.Control.Feedback type="invalid">
              {errors.first_name.message || "Required"}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group controlId="form-last-name" as={Col}>
          <Form.Label>Last Name *</Form.Label>
          <Form.Control
            type="text"
            placeholder=""
            name="last_name"
            autoComplete="family-name"
            ref={register({ required })}
            disabled={isSubmitting}
            isInvalid={!!errors.last_name}
          />
          {errors.last_name && (
            <Form.Control.Feedback type="invalid">
              {errors.last_name.message || "Required"}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group controlId="form-email" as={Col} xs={12} sm>
          <Form.Label>Email *</Form.Label>
          <Form.Control
            type="email"
            placeholder=""
            name="email"
            autoComplete="email"
            ref={register({ required })}
            disabled={isSubmitting}
            isInvalid={!!errors.email}
          />
          {errors.email && (
            <Form.Control.Feedback type="invalid">
              {errors.email.message || "Required"}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group controlId="form-phone" as={Col} xs={12} sm>
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            type="text"
            placeholder=""
            name="phone"
            autoComplete="tel"
            ref={register()}
            disabled={isSubmitting}
          />
          {errors.phone && (
            <Form.Control.Feedback type="invalid">
              {errors.phone.message || "Required"}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Form.Row>
      <Form.Group controlId="form-message">
        <Form.Label>Message *</Form.Label>
        <Form.Control
          as="textarea"
          rows="3"
          name="message"
          ref={register({ required })}
          disabled={isSubmitting}
          isInvalid={!!errors.message}
        />
        {errors.message && (
          <Form.Control.Feedback type="invalid">
            {errors.message.message || "Required"}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group>
        <Recaptcha
          ref={recaptchaRef}
          sitekey={process.env.GOOGLE_RECAPTCHA_SITE_KEY}
          callback={(val) => {
            setValue("recaptcha", val)
          }}
          expiredCallback={(val) => {
            window.grecaptcha.reset()
          }}
          locale="en-US"
          className={!!errors.recaptcha ? "is-invalid" : ""}
          data-theme="light"
        />
        {errors.recaptcha && (
          <Form.Control.Feedback type="invalid">
            {errors.recaptcha.message || "Required"}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      {!!errors.submit && (
        <div className="pt-0 pb-3 text-danger">{errors.submit.message}</div>
      )}
      <Button type="submit" disabled={isSubmitting} className="btn-submit">
        {isSubmitting ? (
          <Spinner animation="border" className="spinner" />
        ) : (
          "Send Message"
        )}
      </Button>
    </Form>
  )

  return (
    <Layout
      headerTitle="Contact Us"
      headerDescription="Confused about something? We get it! Ask us anything and everything."
      headerHideLinks={true}
    >
      <SEO title="Contact Us" />
      <Container>
        {submitted && Object.keys(errors).length === 0
          ? showThankYou
          : showForm}
      </Container>
    </Layout>
  )
}

export default ContactUsPage
